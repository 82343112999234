* {
  margin: 0;
}
.app__dropdown {
  background-color: white;
}
.app__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.app__header > h1 {
  color: #fc3c3c;
  font-size: 2rem;
}
.app__stats {
  display: flex;
  justify-content: space-between;
}
body {
  background-color: #f5f6fa;
}
.App {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}
.App__left {
  flex: 0.8;
}
@media (max-width: 990px) {
  .App {
    flex-direction: column;
  }
}
.App__right {
  display: flex;
  flex-direction: column;
}
.App__right .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.app__graph {
  flex-grow: 1;
}
.app__graphTitle {
  margin-top: 20px;
  margin-bottom: 20px;
}
