.infoBox {
  flex: 1;
  border-top: 10px solid white;
}

.infoBox:not(:last-child) {
  margin-right: 15px;
}

.infoBox__cases {
  color: #cc1034;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 01.75rem;
}
.infoBox__total {
  color: #6e757d;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  margin-top: 15px !important;
}

.infoBox__cases--selected {
  border-top: 10px solid #cc1034;
}
.infoBox__recovered--selected {
  border-top: 10px solid #7dd71d;
}

.infoBox__deaths--selected {
  border-top: 10px solid #fb4443;
}
